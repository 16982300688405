// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  welcome_page: `صفحة الترحيب`,
  Sun: "الأحد",
  Mon: "الإثنين",
  Tue: "الثلاثاء",
  Wed: "الأربعاء",
  Thu: "الخميس",
  Fri: "الجمعة",
  Sat: "السبت",
  full_name: "الاسم الكامل",
  unpaid: "غير مسدد",
  paid: "مسدد",
  cash: "كاش",
  approved: "موافق عليها",
  rejected: "مرفوضة",
  pending: " بانتظار الموافقة",
  salary_deduction: "خصم من الراتب",
  partially_paid:"مدفوع جزئياً",
  COMPANY: "شركة",
  INDIVIDUAL: "أفراد",
  under_review:"لم يتم تسليمه بعد",
  delivered:" مُسلّم",
  not_delivered:" تحت المراجعة",
};

export default ar;
