import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {
  useTable,
  TableHeadCustom,
  TableSelectedAction,
  TableSkeleton,
  TableNoData,
  TablePaginationCustom,
} from "../../../../../components/table";

import { IconButton, Tooltip } from "@mui/material";

import Iconify from "../../../../../components/iconify";

import Scrollbar from "../../../../../components/scrollbar";

import ProceduresTableRow from "./ProceduresTableRow";

import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { getProceduressDetails } from "../../../../../redux/services/debt_collection_service";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

// ----------------------------------------------------------------------

export default function ProceduresList({ currentTab, personalMode }) {
  const {
    procedures,
    isLoadingProcedures,
    columnsList,
    proceduresCount,
    opponentId,
  } = useSelector((state) => state.deptCollection);

  const selectedColumns = columnsList.filter((item) => item.status);

  const TABLE_HEAD = [
    ...selectedColumns.map((item) => ({
      id: item.id,
      label: item.name,
      align: "center",
    })),
    { id: "", label: "", align: "center" },
  ];

  const dispatch = useDispatch();

  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  //

  const [tableData, setTableData] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setTableData(procedures);
  }, [procedures]);

  useEffect(() => {
    dispatch(
      getProceduressDetails({
        id: opponentId,
        page: 1,
        personalMode: personalMode,
      })
    );
  }, [personalMode]);

  const handleChange = (event, value) => {
    setPage(value);
    const typeMap = {
      0: undefined,
      1: "communication",
      2: "execute",
    };

    dispatch(
      getProceduressDetails({
        id: opponentId,
        page: value,
        type: typeMap[currentTab],
      })
    );
  };

  //____________________________________________________________
  const handleDelete = () => {
    // dispatch delete here ...
  };
  let content;

  switch (true) {
    case isLoadingProcedures:
      content = (
        <>
          {Array.from({ length: 4 }).map((_, index) => (
            <TableSkeleton key={index} />
          ))}
        </>
      );
      break;
    case tableData?.length > 0:
      content = tableData?.map((row) => (
        <ProceduresTableRow
          key={row.id}
          row={row}
          onSelectRow={() => onSelectRow(row?.id)}
          selected={selected}
          selectedColumns={selectedColumns}
        />
      ));
      break;
    default:
      content = <TableNoData isNotFound />;
      break;
  }

  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF" onClick={handleDelete}>
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{ ...tableHolderSx }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )
              }
            />

            <TableBody>
              <>{content}</>

              {/* <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData?.length)}
              /> */}
            </TableBody>
          </Table>
        </Scrollbar>
        {tableData?.length > 0 && (
          <TablePaginationCustom
            count={proceduresCount}
            page={page}
            handleChange={handleChange}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        )}
      </StyledTableContainer>
    </>
  );
}
