import React, { useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import { styled } from "@mui/material/styles";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import useClientSearch from "../../../../hooks/useClientSearch";

import { getAllClientsService } from "../../../../redux/services/general_service";

import { exportAssignmentService } from "../../../../redux/services/debt_collection_service";

import ProceduresTab from "../tabs/opponent-tab/ProceduresTab";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

//___________________________________________________________
export default function ProceduresAccordion({
  setValue,
  control,
  errors,
  reset,
}) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedFileStatus, setSelectedFileStatus] = useState({});

  const [selectedAction, setSelectedActionStage] = useState({});

  const [selectedCourt, setSelectedCourt] = useState({});

  const { clients } = useSelector((state) => state.general);

  const { courts } = useSelector((state) => state.matter);

  const { filteredClients } = useClientSearch(clients);

  const [selectedClient, setSelectedClient] = useState({});
  const [expanded, setIsExpanded] = useState(true);

  const { OppDetails, fileStatus, actionStage, opponentId } = useSelector(
    (state) => state?.deptCollection
  );

  useEffect(() => {
    dispatch(getAllClientsService({ page: 1 }));
  }, []);

  useEffect(() => {
    setSelectedClient(
      filteredClients?.find((cl) => cl.id == OppDetails?.client)
    );
    setSelectedCourt(courts?.find((c) => c.id == OppDetails?.execution_court));
    setSelectedFileStatus(OppDetails?.file_status);
    setSelectedActionStage(OppDetails?.action_stage);
  }, [OppDetails, filteredClients]);

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          // onClick={() => {
          //   setIsExpanded(!expanded);
          // }}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={boxAccordionTitleStyle}> الاجراءات</Typography>
            {OppDetails?.file_status !== "سداد نهائي" && (
              <RouterLink to={"/dashboard/collection/create-procedure"}>
                <Box>
                  <img
                    src={"/assets/icons/plus_white.svg"}
                    alt="add"
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                  />
                </Box>
              </RouterLink>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <ProceduresTab />
        </AccordionDetails>
      </CustomizedAccordion>
    </>
  );
}
