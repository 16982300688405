import React from "react";

import ProceduresList from "../../table/procedures-list";

// @ Mui
import {Box} from "@mui/material";

// -----------------------------------------------------------------------------

export default function AllTab({currentTab, personalMode}) {

  // ------------- JSX Code ----------

  return (
    <>
      <Box sx={{mt: "20px"}}>
        <ProceduresList currentTab={currentTab} personalMode={personalMode}/>
      </Box>
    </>
  );
}
