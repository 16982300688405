import React, { useState } from "react";

import PropTypes from "prop-types";
// @ Mui
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import {
  setEmployeeId,
  setEmployeeName,
} from "../../../../../redux/slices/general";

import { useDispatch, useSelector } from "react-redux";

import Iconify from "../../../../../components/iconify";

import MenuPopover from "../../../../../components/menu-popover";

import CutomeDialog from "../../../../../components/cutome-dialog";

import { Box } from "@mui/system";

import { LoadingButton } from "@mui/lab";

import {
  getEmployeeService,
  removeUserService,
  deleteUserService,
} from "../../../../../redux/services/debt_collection_service";

import { successToast } from "../../../../../utils/toastMessage";

import { baseUrl } from "../../../../../urls";

import { toast } from "react-toastify";

import { getEmployeeDetails } from "../../../../../redux/services/general_service";

// ------------------------------------------------------------------------

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));

const menuStyle = {
  width: "auto",
  boxShadow: " 2px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  border: "1px solid rgba(103, 103, 103, 0.1)",
  p: "16px 22px 16px 22px",
};

const cancelBtnSx = {
  background: "#FFFFFF",
  color: "#2F2B2C",
  lineHeight: "28px",
  border: "1px solid #CCDDE4",
  fontSize: "16px",
  fontWeight: "400",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  boxShadow: " 0px 0.75px 1.5px rgba(16, 24, 40, 0.05)",
  borderRadius: "6px",
  width: "160px",
};

const menuItemTextStyle = {
  ml: "10px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#2F2B2C",
};

const boxOfActionButtonStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  paddingLeft: "20px",
  paddingRight: "20px",
};

const confirmBtnSx = {
  fontSize: "16px",
  fontWeight: "600",
  color: "#FEFEFE",
  lineHeight: "28px",
  bgcolor: "#D51A52",
  "&:hover": {
    bgcolor: "grey.800",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  boxShadow: " 0px 0.75px 1.5px rgba(16, 24, 40, 0.05)",
  borderRadius: "6px",
  width: "160px",
};

//_______________________________________________________

EmployeeTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function EmployeeTableRow(props) {
  const userData = localStorage.getItem("user");

  if (userData) {
    // Parse the JSON string back into an object
    const user = JSON.parse(userData);

    // Access the employee_id
    const employeeId = user.employee_id;

    console.log("Employee ID:", employeeId);
  } else {
    console.log("No user data found in local storage.");
  }

  const { row, selected, onSelectRow, selectedColumns } = props;

  const { jobs, isLoadingRemvoe, isLoadingDelete } = useSelector(
    (state) => state.deptCollection
  );

  let selectedJob = jobs?.find((j) => j.id === row.job)?.name;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [title, setTilte] = useState("");

  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState(null);

  const [content, setContent] = useState("");

  const [body, setBody] = useState("");

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const handleDeleteClick = () => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);

      const employeeId = user.employee_id;

      // Check if the employee is trying to delete themselves
      if (employeeId === row.id) {
        toast.error("لا يمكنك حذف حسابك الخاص.");
        return; // Prevent deletion
      }

      // Proceed with deletion
      setIsLoadingRemove(true);
      dispatch(deleteUserService({ id: row.id })).then((response) => {
        setIsLoadingRemove(false);
        if (response?.payload?.status === 200) {
          toast.success("تمت عملية الحذف بنجاح");
          setOpen(false);
          dispatch(getEmployeeService({ page: 1 }));
        } else {
          toast.error("حدث خلل أثناء الحذف");
        }
      });
    }
  };

  // Get the employee_id from local storage

  let employeeId = null;

  if (userData) {
    const user = JSON.parse(userData);

    employeeId = user.employee_id;
  }

  const renderCellContent = (column) => {
    switch (column.id) {
      case "0":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>
                {row.first_name} {row?.last_name}
              </Typography>
            </Stack>
          </TableCell>
        );

      case "1":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>{selectedJob}</Typography>
            </Stack>
          </TableCell>
        );

      case "2":
        return (
          <TableCell align="center" sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>
                {row?.first_phone_number}
              </Typography>
              {/* <Typography sx={{ ...cellTextSx }}>
                {row?.phone_numbers?.map((phone, index) => (
                  <div>{phone?.phone_number}</div>
                ))}{" "}
              </Typography> */}
            </Stack>
          </TableCell>
        );

      case "3":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              <Typography sx={{ ...cellTextSx }}>{row?.email} </Typography>
            </Stack>
          </TableCell>
        );

      case "4":
        return (
          <TableCell sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              {row?.profile_picture !== null && (
                <img
                  style={{ maxWidth: "100%", width: "40px", height: "40px" }}
                  src={baseUrl + row?.profile_picture}
                  alt=""
                />
              )}
            </Stack>
          </TableCell>
        );

      default:
        return null;
    }
  };

  // -------- JSX Code -------
  return (
    <>
      <StyledTableRow
        onClick={() => {
          // navigate("/dashboard/employee");
          dispatch(setEmployeeId(row?.id));
        }}
        hover
        selected={selected}
      >
        <TableCell padding="checkbox" sx={innerCellBorderStyle} />

        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.id} </Typography>
          </Stack>
        </TableCell>

        {selectedColumns.map((column) => (
          <>{renderCellContent(column)}</>
        ))}

        {/** ACTIONS*/}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          align="center"
          sx={innerCellBorderStyle}
        >
          <Typography component="div" sx={{ ...cellTextSx }}>
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Typography>
        </TableCell>

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        >
          {/*********************edit****************************************** */}
          <MenuItem
            onClick={async (e) => {
              e.stopPropagation();

              await dispatch(setEmployeeId(row?.id));
              await dispatch(setEmployeeName(row?.first_name));
              await dispatch(getEmployeeDetails({ id: row?.id }));
              navigate("/dashboard/hr/employee/edit-employee");
              handleClosePopover();
            }}
          >
            <img alt="" disabledEffect src="/assets/icons/edit.svg" />
            <Typography sx={{ ...menuItemTextStyle }}>تعديل</Typography>
          </MenuItem>

          {employeeId !== row.id && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleClosePopover();
                setOpen(true);
                setTilte("هل تريد حقاً حذف هذا الموظف");
              }}
            >
              <img alt="" disabledEffect src="/assets/icons/red_delete.svg" />
              <Typography sx={{ ...menuItemTextStyle, color: "#D51A52" }}>
                حذف الموظف{" "}
              </Typography>
            </MenuItem>
          )}
          {/**************************************************************** */}
        </MenuPopover>
      </StyledTableRow>

      {open && (
        <CutomeDialog
          open={open}
          onClose={() => setOpen(false)}
          title={title}
          content={content}
          body={body}
          action={
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                /**onClick={() => {
                  dispatch(removeUserService({ id: row?.id })).then(
                    (response) => {
                      if (response?.payload?.status == 204) {
                        successToast("تمت عملية الحذف  بنجاح");
                        setOpen(false);
                        dispatch(getEmployeeService({ page: 1 }));
                      } else {
                        toast.error("حدث خلل أثناء الحذف ");
                      }
                    }
                  );
                }} */

                loading={isLoadingDelete}
                variant="contained"
                sx={confirmBtnSx}
                onClick={handleDeleteClick}
              >
                حذف
              </LoadingButton>

              <Button sx={cancelBtnSx} onClick={() => setOpen(false)}>
                الغاء
              </Button>
            </Box>
          }
        />
      )}
    </>
  );
}
