import React, { useState, useEffect } from "react";

import { Box, Typography, Grid, MenuItem } from "@mui/material";

// form
import { RHFTextField, RHFSelect } from "../../../../components/hook-form";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import { styled } from "@mui/material/styles";

import { LoadingButton } from "@mui/lab";

import { useDispatch, useSelector } from "react-redux";

import useClientSearch from "../../../../hooks/useClientSearch";

import { getAllClientsService } from "../../../../redux/services/general_service";

import { exportAssignmentService } from "../../../../redux/services/debt_collection_service";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";

import { useNavigate } from "react-router-dom";

import AddVoucherModal from "../modal/AddVoucherModal";

import EditVoucherModal from "../modal/EditVoucherModal";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(47, 43, 44, 1)",
  pb: "8px",
};

const MainSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#97BDCE",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  textAlign: "center",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#1A141F",
  paddingBottom: "4px",
};

const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",
  mb: "8px",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const bigFlexBoxStyle = {
  display: "flex",
  gap: "0.5em",
  alignItems: "center",
};

const dateNameStyle = {
  fontSize: "16px",
  fontWeight: 600,
  color: "rgba(47, 43, 44, 1)",
  lineHeight: "28px",
};

const readOnluStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#818181",
  lineHeight: "28px",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};

const loadingButtonStyle = {
  borderRadius: "5px",
  width: "268px",
  fontSize: "16px",
  height: "40px",
  fontWeight: 400,
  //bgcolor: "#97BDCE",
  color: "#6A8490",
  border: "2px solid #6A8490",
  paddingLeft: "18px",
  paddingright: "18px",
  paddingTop: "10px",
  paddingBottom: "10px",
  textAlign: "center",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const multiRowBorderStyle = {
  width: "100%",

  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    //height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

//___________________________________________________________
export default function MainInformationForm({
  setValue,
  control,
  errors,
  reset,
  handleSubmit,
  onSubmit,
  defaultValues,
  watch,
}) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { info, courtTable, stageTable, fileTable } = useSelector(
    (state) => state.deptCollection
  );

  const {
    OppDetails,
    fileStatus,
    actionStage,
    isLoadingExport,
    opponentId,
    isLoadingOppUpdate,
  } = useSelector((state) => state?.deptCollection);
  const [selectedFileStatus, setSelectedFileStatus] = useState("");

  const [selectedAction, setSelectedActionStage] = useState("");

  const [selectedCourt, setSelectedCourt] = useState("");

  const { clients } = useSelector((state) => state.general);

  const { courts } = useSelector((state) => state.matter);

  const { filteredClients } = useClientSearch(clients);

  const [selectedClient, setSelectedClient] = useState({});

  const [expanded, setIsExpanded] = useState(true);
  // =================================================================
  const [phones, setPhones] = useState([...Array(1)]);

  const phoneNumbers = watch("phone_numbers") || [];

  const addNewPhoneHandler = () => {
    setValue("phone_numbers", [...phoneNumbers, { phone_number: "" }]);
  };

  const removePhoneHandler = (index) => {
    const updatedPhones = phoneNumbers.filter((_, i) => i !== index);
    setValue(
      "phone_numbers",
      updatedPhones.length > 0 ? updatedPhones : [{ phone_number: "" }]
    );
  };

  useEffect(() => {
    setPhones(phoneNumbers);
  }, [phoneNumbers]);

  // =================================================================
  const [openModal, setOpenModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseEdit = () => {
    setOpenEditModal(false);
  };

  const depDetails = [
    { label: "الكفيل:", value: OppDetails?.opponent_dependencies?.full_name },
    {
      label: "الصفة:",
      value: OppDetails?.opponent_dependencies?.attribute ?? "",
    },
    {
      label: "هاتف الكفيل:",
      value: OppDetails?.opponent_dependencies?.phone_number,
    },
  ];
  // =================================================================

  const file_status = fileStatus?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const action_stage = actionStage?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const cou = courts?.map((item) => ({
    id: item.id, // Assuming item has an 'id' property
    name: item.name,
  }));

  const handleExportAssignment = () => {
    dispatch(exportAssignmentService({ id: opponentId }));
  };

  useEffect(() => {
    dispatch(getAllClientsService({ page: 1 }));
  }, []);

  //useEffect(() => {
  //  console.log("info.execution_court", info.execution_court);
  // }, []);

  useEffect(() => {
    setSelectedClient(OppDetails?.client);
    // setSelectedCourt(courts?.find((c) => c.id === OppDetails?.execution_court));
  }, [OppDetails, filteredClients]);

  useEffect(() => {
    setSelectedCourt(courtTable);
    setSelectedFileStatus(fileTable);
    setSelectedActionStage(stageTable);
  }, [courtTable, fileTable, stageTable, filteredClients]);

  const [daysDifference, setDaysDifference] = useState(0);

  useEffect(() => {
    const calculateDaysDifference = () => {
      const entry = new Date(OppDetails?.entry_date);
      const current = new Date();
      const differenceInTime = current - entry;
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 3600 * 24)
      );
      setDaysDifference(differenceInDays);
    };

    calculateDaysDifference();
    //console.log("OppDetails?.opponent_dependencies");
   // console.log(OppDetails?.opponent_dependencies);
  }, [OppDetails?.entry_date, openEditModal]);

  //_________________________________________________________________

  // Check if info.file_status exists in file_status
  const fileStatusExists = file_status.some(
    (status) => status.value === fileTable
  );

  // If it doesn't exist, add it as the first item
  const updatedFileStatus = fileStatusExists
    ? file_status
    : [{ value: fileTable, label: fileTable }, ...file_status];

  //action_stage
  // Check if info.file_status exists in file_status
  const actionStageExists = action_stage.some(
    (status) => status.value === stageTable
  );

  // If it doesn't exist, add it as the first item
  const updatedActionStageStatus = actionStageExists
    ? action_stage
    : [{ value: stageTable, label: stageTable }, ...action_stage];

  //courts
  // Check if info.execution_court exists in cou
  const courtsExists = cou.some((court) => court.name === courtTable);

  // If it doesn't exist, add it as the first item
  const updatedCourtsStatus = courtsExists
    ? cou
    : [{ id: null, name: courtTable }, ...cou];
  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          // onClick={() => {
          //   setIsExpanded(!expanded);
          // }}
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={boxAccordionTitleStyle}>معلومات أساسية</Typography>

            <Box sx={flexBoxStyle}>
              {/** */}
              {/* <Button
                onClick={() => navigate(-1)}
                size="large"
                variant="Contained"
                sx={topCancleButtonStyle}
              >
                الغاء
              </Button> */}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**  الإسناد  */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap="0.5em">
                <Typography sx={dateNameStyle}>الموكل</Typography>

                {/* </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6}> */}
                <RHFSelect
                  name="client"
                  control={control}
                  value={selectedClient}
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "'اختر موكلاً'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img
                        src="/assets/icons/Icon.svg"
                        alt=""
                        style={{
                          marginRight: "19px",
                          marginLeft: "19px",
                        }}
                      />
                    ),
                  }}
                  displayEmpty
                  onChange={(e) => {
                    setValue("client", e.target.value, {
                      shouldValidate: true,
                    });
                    setSelectedClient(e.target.value);
                  }}
                >
                  {filteredClients?.map((client, index) => (
                    <MenuItem
                      sx={{
                        borderBottom: "1px solid  #E5E0EB",
                      }}
                      key={index}
                      value={client?.id}
                    >
                      {client?.full_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Box>
            </Grid>
          </Grid>
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={bigFlexBoxStyle}>
                <Typography sx={dateNameStyle}> رقم الملف:</Typography>
                <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
              </Box>
              <RHFTextField
                name="file_number"
                value={OppDetails?.file_number}
                disabled
                // control={control}
                type="text"
                placeholder="أدخل رقم الملف"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>اسم الخصم:</Typography>
              <RHFTextField
                name="full_name"
                // control={control}
                type="text"
                placeholder="أدخل اسم الخصم"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
          </Grid>
          {/** */}
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/** حالة الملف  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>حالة الملف</Typography>

              <RHFSelect
                name="file_status"
                control={control}
                value={selectedFileStatus}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر حالة الملف'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedFileStatus(e.target.value);
                  console.log("e.target.value", e.target.value);
                  setValue("file_status", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {updatedFileStatus.map((s, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={s.value}
                  >
                    {s.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {/**الجنسية */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الجنسية</Typography>
              <RHFTextField
                name="nationality"
                // control={control}
                placeholder="أدخل الجنسية"
                type="text"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**  */}
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={bigFlexBoxStyle}>
                <Typography sx={dateNameStyle}>رقم العقد:</Typography>
                <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
              </Box>
              <RHFTextField
                name="contract_number"
                // control={control}
                type="text"
                disabled
                placeholder="أدخل اسم الخصم"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
            {/** */}
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box sx={bigFlexBoxStyle}>
                    <Typography sx={dateNameStyle}>تاريخ الورود:</Typography>
                    <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
                  </Box>
                  <YearCustomDatePicker
                    name="entry_date"
                    disabled
                    setValue={setValue}
                    sx={{ ...txtFieldBorderStyle }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={bigFlexBoxStyle}>
                    <Typography sx={dateNameStyle}>عدد الأيام:</Typography>

                    <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
                  </Box>
                  <RHFTextField
                    name=""
                    // control={control}
                    disabled
                    type="text"
                    value={daysDifference}
                    placeholder="أدخل اسم الخصم"
                    sx={{
                      ...txtFieldBorderStyle,
                      width: "100%",
                      marginRight: "12px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/** ارقم المدني */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الرقم المدني</Typography>
              <RHFTextField
                name="civil_number"
                placeholder="أدخل الرقم المدني"
                type={"text"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>
            {/**  محكمة التنفيذ  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}> محكمة التنفيذ</Typography>
              <RHFSelect
                name="execution_court"
                control={control}
                value={selectedCourt}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر محكمة التنفيذ'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  console.log("e", e.target.value);

                  setSelectedCourt(e.target.value);
                  setValue("execution_court", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {updatedCourtsStatus?.map((court, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={court?.name}
                  >
                    {court.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
          {/** */}
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/** مرحلة العمل */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>مرحلة العمل</Typography>
              <RHFSelect
                name="action_stage"
                value={selectedAction}
                control={control}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "' اختر مرحلة العمل'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedActionStage(e.target.value);
                  setValue("action_stage", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {updatedActionStageStatus.map((st, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={st.value}
                  >
                    {st.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {/**  سبب التنفيذ */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>سبب التنفيذ</Typography>
              <RHFTextField
                name="reason_for_execution"
                // control={control}
                type="text"
                placeholder="أدخل سبب التنفيذ"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**الرقم المفصول */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الرقم المفصول</Typography>
              <RHFTextField
                name="separated_number"
                placeholder="أدخل الرقم المفصول"
                type={"text"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>

            {/**  رقم أمر الأداء  */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>رقم أمر الأداء</Typography>
              <RHFTextField
                name="performance_number_value"
                // control={control}
                placeholder="XXXXXXX"
                type={"number"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
          </Grid>

          {/** */}
          <Grid
            container
            columnSpacing={6}
            paddingTop={"10px"}
            alignItems={"center"}
          >
            {/**   الرقم الآلي */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>الرقم الآلي</Typography>
              <RHFTextField
                name="automatic_number"
                // control={control}
                type={"text"}
                placeholder="XXXXXXX0"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>

            {/** تاريخ نزول المحكمة */}
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>تاريخ نزول المحكمة</Typography>
              <YearCustomDatePicker
                name="date_of_court_filing"
                setValue={setValue}
                sx={{ ...txtFieldBorderStyle }}
              />
            </Grid>
          </Grid>

          {/** */}
          {/**  قيمة أمر الأداء  */}
          {/* <Grid container columnSpacing={6} paddingTop={"10px"}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography sx={lableStyle}>قيمة أمر الأداء</Typography>
              <RHFTextField
                name="performance_fee_value"
                // control={control}
                placeholder="قيمة أمر الأداء"
                type={"number"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
          </Grid> */}
          <Grid container paddingTop={"8px"}>
            {/**   */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}>رقم الهاتف</Typography>

              <Box
                sx={{
                  display: "flex",
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                {phones?.map((phone, index) => (
                  <Grid item xs={12} sm={12} md={6} mb="15px">
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        mt: "10px",
                      }}
                    >
                      <RHFTextField
                        name={`phone_numbers[${index}].phone_number`}
                        // control={control}
                        placeholder="أدخل رقم الهاتف"
                        type="text"
                        sx={{
                          ...txtFieldBorderStyle,
                          width: "100%",
                          marginRight: "12px",
                        }}
                      />

                      {index > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                          onClick={() => removePhoneHandler(index)}
                        >
                          -
                        </Box>
                      )}

                      {index === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={addNewPhoneHandler}
                            src="/assets/icons/plus.svg"
                            alt=""
                            style={{
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Box>
            </Grid>
            <Grid container paddingTop={"10px"}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: "0.5em",
                  }}
                >
                  {OppDetails?.opponent_dependencies?.id ? (
                    depDetails.map((detail, index) => (
                      <>
                        <Box key={index} onClick={() => setOpenEditModal(true)}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5em",
                            }}
                          >
                            <Typography
                              sx={{
                                ...lableSx,
                                color: "#6a8490",
                              }}
                            >
                              {detail.label}
                            </Typography>
                            <Typography>{detail.value}</Typography>
                          </Box>

                          {OppDetails?.opponent_dependencies?.attachments?.map(
                            (atach, index) => (
                              <></>
                            )
                          )}
                        </Box>
                      </>
                    ))
                  ) : (
                    <Box onClick={() => setOpenModal(true)}>
                      <img
                        src="/assets/icons/plus.svg"
                        alt=""
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      />
                      <Typography sx={lableSx}> الكفيل</Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container paddingTop={"10px"}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}> العنوان</Typography>
              <RHFTextField
                name="address"
                // control={control}
                placeholder="أدخل العنوان"
                id="filled-multiline-static"
                multiline
                rows={3}
                type={"text"}
                sx={{
                  ...multiRowBorderStyle,
                  "& .MuiOutlinedInput-input": {
                    width: "100%",
                  },
                }}
              />
            </Grid>
          </Grid>

          {/** */}
          <Grid
            container
            columnSpacing={6}
            paddingTop={"20px"}
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
          >
            {/**   */}
            <Grid item xs={12} sm={12} md={6} textAlign="center">
              <LoadingButton
                fullWidth
                size="large"
                //type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoadingExport}
                onClick={handleExportAssignment}
              >
                إصدار تنبيه وتكليف
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} textAlign="center">
              <LoadingButton
                // fullWidth
                onClick={handleSubmit(onSubmit)}
                size="large"
                type="submit"
                variant="Contained"
                sx={MainSaveButtonStyle}
                loading={isLoadingOppUpdate}
              >
                حفظ
              </LoadingButton>
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomizedAccordion>
      {openModal && (
        <AddVoucherModal open={openModal} onCloseModel={handleClose} />
      )}

      {openEditModal && (
        <EditVoucherModal
          open={openEditModal}
          detailes={OppDetails?.opponent_dependencies}
          onCloseModel={handleCloseEdit}
        />
      )}
    </>
  );
}
