import React, { useEffect, useState } from "react";

import { Box, Tabs, Tab, Checkbox, Typography } from "@mui/material";

// Tabs
import AllTab from "../procedures-tab/AllTab";

import ExcusiveTab from "../procedures-tab/ExcusiveTab";

import TelecomTab from "../procedures-tab/TelecomTab";

// filter
import FilterOpponentAccordion from "../../accordion/FilterOpponentAccordion";

import ColumnsAccordionProsedure from "../../../lawsuit/accordion/ColumnsAccordionProsedure";

import { getProceduressDetails } from "../../../../../redux/services/debt_collection_service";

import { useDispatch, useSelector } from "react-redux";

import { setProceduerTab } from "../../../../../redux/slices/deptCollection";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const styleTabs = {
  minHeight: "40px",
  bgcolor: "#F5F5F5",
  "& button.Mui-selected": {
    background: "#6A8490",
    p: "0px 24px 0px 24px",
    color: "#FEFEFE",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "28px",
    // minHeight: "36px",
    minWidth: "78px",
  },

  "& .MuiTabs-flexContainer": {
    justifyContent: {
      sm: "flex-star",
      md: "flex-star",
      lg: "flex-start",
      xs: "flex-start",
    },
    display: "flex",
    alignItems: "center",
  },
  "& .MuiTabs-scroller": {
    display: "flex",
    alignItems: "center",
  },
  "& .Mui-selected": {},

  "& .MuiButtonBase-root:not(.Mui-selected)": {
    color: " #2F2B2C",
    fontSize: "14px",
    fontWeight: 400,
    minHeight: "36px",
    lineHeight: "20px",
    //border: "0.5px solid #697D95",
    width: "78px",
  },
};

const tabBoxStyle = {
  height: "40px",
  display: "flex",
  alignItems: "center",
  //mt: "20px",
  // mb: "10px",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const caseNameStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#6A8490",
  lineHeight: "20px",
};

//_______________________________________________

export default function ProceduresTab() {
  const dispatch = useDispatch();

  const { opponentId, proTab } = useSelector((state) => state.deptCollection);

  const [currentTab, setCurrentTab] = useState(proTab);

  const [personalMode, setPersonalMode] = useState(false);

  useEffect(() => {
    const data = {
      id: opponentId,
      page: 1,
      personalMode: personalMode,
    };
    if (currentTab !== "0") {
      data.type = currentTab;
    }
    dispatch(getProceduressDetails(data));
  }, [currentTab, opponentId, dispatch]);

  useEffect(() => {
    dispatch(setProceduerTab("1"));
  }, []);

  const TABS = [
    {
      value: "0",
      label: "الكل",
      component: <AllTab currentTab={currentTab} personalMode={personalMode} />,
    },
    {
      value: "1",
      label: "تنفيذ",
      component: (
        <ExcusiveTab currentTab={currentTab} personalMode={personalMode} />
      ),
    },
    {
      value: "2",
      label: "اتصالات",
      component: (
        <TelecomTab currentTab={currentTab} personalMode={personalMode} />
      ),
    },
    {
      value: "3",
      label: "تجهيز",
      component: (
        <ExcusiveTab currentTab={currentTab} personalMode={personalMode} />
      ),
    },
  ];

  function handlePersonalModeChanged() {
    setPersonalMode(!personalMode);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          mt: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/** Tabs */}
          <Box sx={tabBoxStyle}>
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => {
                setCurrentTab(newValue);
                dispatch(setProceduerTab(newValue));
                // alert(proTab);
              }}
              sx={styleTabs}
              TabIndicatorProps={
                {
                  // style: { display: "none" },
                }
              }
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
          </Box>

          <FilterOpponentAccordion personalMode={personalMode} />
          {/** columns */}
          <ColumnsAccordionProsedure />
          {/** الوضع الشخصي */}
          <Box sx={{ ...flexBoxStyle }}>
            <Checkbox
              checked={personalMode}
              onClick={handlePersonalModeChanged}
              sx={{ ...checkBoxSx }}
            />
            <Typography sx={caseNameStyle}>الوضع الشخصي</Typography>
          </Box>
        </Box>

        {/* <LawsuitSearchBar placeholder={"البحث عن إجراء"} /> */}
      </Box>

      {TABS.map(
        (tab) =>
          tab.value == currentTab && (
            <Box key={tab?.value}>{tab?.component}</Box>
          )
      )}
    </>
  );
}
