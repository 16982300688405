import { Helmet } from "react-helmet-async";

import React, { useEffect, useMemo, useState } from "react";

import { Box, Typography, Divider, Alert, Skeleton } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import DetailsTab from "../../../sections/dashboard/collection/tabs/opponent-tab/DetailsTab";

import { useForm } from "react-hook-form";

import FormProvider from "../../../components/hook-form";

import UploadFileModal from "../../../sections/dashboard/collection/modal/UploadFileModal";

import {
  getActionStageService,
  getClientsCollectionService,
  getEmployeeService,
  getExpenseOpponents,
  getFileStatusService,
  getOpponentsDetails,
  getPaymentsService,
  updateOpponentDetails,
} from "../../../redux/services/debt_collection_service";

import { getCourtsService } from "../../../redux/services/matter_service";

import { toast } from "react-toastify";

import {
  setProcedureId,
  setCourt,
  setStage,
  setFile,
} from "../../../redux/slices/deptCollection";

//_________________________________________________________________

//---------- Styles -----------

const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: { xs: "15px", sm: "0px" },
};

const skeletonStyle = {
  borderRadius: "4px",
  bgcolor: "rgba(0,0,0,0.05)",
  marginBottom: "1em",
};

const dividerStyle = {
  mt: "20px",
};

//___________________________________________________________
export default function OpponentProfilePage() {
  const dispatch = useDispatch();
  const { oppTab } = useSelector((state) => state.general);

  // const { info } = useSelector((state) => state.deptCollection);

  const {
    OppDetails,
    opponentId,
    isLoadingOppUpdate,
    isLoadingOppDetails,
    fileStatus,
  } = useSelector((state) => state.deptCollection);

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleClose = () => {
    setOpenAddModal(null);
  };

  const navigate = useNavigate();

  const { control } = useForm();

  const defaultValues = useMemo(
    () => ({
      action_stage: OppDetails?.action_stage || "",
      address: OppDetails?.address || "",
      amount_paid: OppDetails?.amount_paid || "",
      assigned_employee: OppDetails?.assigned_employee || null,
      automatic_number: OppDetails?.automatic_number || "",
      city: OppDetails?.city || "",
      claim_value: OppDetails?.claim_value || "",
      client: OppDetails?.client || null,
      contract_number: OppDetails?.contract_number || "",
      email: OppDetails?.email || "",
      entry_date: OppDetails?.entry_date || "",
      execution_court: OppDetails?.execution_court || "",
      expenses: OppDetails?.expenses || "",
      file_status: OppDetails?.file_status || "",
      full_name: OppDetails?.full_name || "",
      id: OppDetails?.id || null,
      performance_fee_value: OppDetails?.performance_fee_value || "",
      performance_number_value: OppDetails?.performance_number_value || "",
      phone_numbers: OppDetails?.phone_numbers || [],
      province: OppDetails?.province || "",
      reason_for_execution: OppDetails?.reason_for_execution || "",
      total_claim: OppDetails?.total_claim || "",
      total_remainder: OppDetails?.total_remainder || "",
      nationality: OppDetails?.nationality || "",
      civil_number: OppDetails?.civil_number || "",
      date_of_court_filing: OppDetails?.date_of_court_filing || null,
      separated_number: OppDetails?.separated_number || null,
    }),
    [OppDetails]
  );

  const methods = useForm({
    // resolver: yupResolver(FormScheema),
    defaultValues,
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    if (Array.isArray(data.phone_numbers)) {
      data.phone_numbers = data.phone_numbers.filter(
        (item) => item.phone_number && item.phone_number.trim() !== ""
      );
      if (data.phone_numbers.length === 0) {
        delete data.phone_numbers;
      }
    }
    dispatch(
      updateOpponentDetails({
        id: opponentId,
        data,
      })
    ).then((response) => {
      if (response?.payload?.status === 200) {
        toast.success("تم  التعديل بنجاح");

        dispatch(setCourt(data.execution_court));
        dispatch(setFile(data.file_status));
        dispatch(setStage(data.action_stage));
      } else {
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });
  };

  useEffect(() => {
    dispatch(getPaymentsService({ id: opponentId, page: 1 }));
    dispatch(getOpponentsDetails({ id: opponentId }));
    dispatch(getClientsCollectionService());
    dispatch(getCourtsService());
    dispatch(getFileStatusService());
    dispatch(getActionStageService());
    dispatch(getEmployeeService({ page: 1 }));
    dispatch(getExpenseOpponents({ id: opponentId, page: 1 }));
    dispatch(setProcedureId(null));
  }, [dispatch]);

  useEffect(() => {
    if (opponentId) {
      reset(defaultValues);
    }
  }, [opponentId, reset, OppDetails, defaultValues]);
  //________________________________________

  return (
    <>
      <Helmet>
        <title> التحصيل</title>
      </Helmet>
      {/** App Bar */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <Box
          sx={{
            ...bigFlexBoxStyle,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={flexBoxStyle}>
            {" "}
            <img
              src="/assets/icons/arrow-left.svg"
              alt=""
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Typography sx={lawsuitTextStyle}>
              {" "}
              {isLoadingOppDetails ? (
                <Skeleton sx={skeletonStyle} width={150} />
              ) : (
                OppDetails?.full_name
              )}
            </Typography>
          </Box>

          {/** actions */}

          <Box sx={flexBoxStyle}>
            {/* <LoadingButton
              fullWidth
              onClick={handleSubmit(onSubmit)}
              size="large"
              type="submit"
              variant="Contained"
              sx={topSaveButtonStyle}
            >
              إضافة جديد
            </LoadingButton> */}
          </Box>
        </Box>
        {/** */}
        <Divider sx={dividerStyle} />

        <DetailsTab
          setValue={setValue}
          errors={errors}
          control={control}
          reset={reset}
          watch={watch}
          isLoadingOppDetails={isLoadingOppDetails}
          methods={methods}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      </FormProvider>

      {openAddModal && (
        <UploadFileModal open={openAddModal} onCloseModel={handleClose} />
      )}
    </>
  );
}
