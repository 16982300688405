import React from "react";

// @mui
import {Box} from "@mui/material";
import ProceduresList from "../../table/procedures-list";

// -----------------------------------------------------------------------------


export default function TelecomTab({currentTab, personalMode}) {

  // ------------- JSX Code ----------

  return (
    <>
      <Box sx={{mt: "20px"}}>
        <ProceduresList currentTab={currentTab} personalMode={personalMode}/>
      </Box>
    </>
  );
}
