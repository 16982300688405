import React, { useState, useEffect } from "react";

import {
  Box,
  Typography,
  MenuItem,
  Autocomplete,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";

// form
import FormProvider from "../../../../components/hook-form";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@mui/material/FormControl";

import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@material-ui/core/ListItemIcon";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";

import { getProceduressDetails } from "../../../../redux/services/debt_collection_service";

import { getEmployeeService } from "../../../../redux/services/debt_collection_service";

import LoadMoreButton from "../../../../utils/LoadMoreButton";

// -----------------------------------------------------------------------------

//* ------- Styles --------
const useStyles = makeStyles((theme) => ({
  formControl: {
    // width: "250px",
    "& .MuiFormLabel-root": {
      fontSize: "16px",
      boxShadow: "#101828",
    },
    "& .MuiOutlinedInput-root": {
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "#101828",
      height: "44px",
      margin: "0px",
      border: "1px solid #D0D5DD",
      "&:hover fieldset": {
        border: "1px solid #D0D5DD",
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
}));

const CustomizedAccordion = styled(Accordion)(() => ({
  width: "160px",
  "&.Mui-expanded": {
    boxShadow: "none",
    padding: 0,
    margin: 0,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": { padding: "0" },

  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    height: "40px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#FEFEFE",
  color: "#2F2B2C",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
};

const bodyOfAccordionStyle = {
  bgcolor: "#EAF2F5",
  boxShadow: "none",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const nameFileStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#FEFEFE",
  lineHeight: "20px",
};

const fileBoxStyle = {
  bgcolor: "#6A8490",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "fit-content",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "8px",
  marginTop: "8px",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#2F2B2C",
  paddingBottom: "4px",
};

const topSaveButtonStyle = {
  mt: "20px",
  mb: "20px",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "184px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#344054",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "184px",
  height: "40px",
  p: "10px 18px 10px 18px",
  border: "1px solid #CCDDE4",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const defaultValuesButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#D51A52",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "100%",
  mt: "30px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  boxShadow: "#101828",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const autocompleteSx = {
  "& .MuiAutocomplete-inputRoot": {
    flexWrap: "wrap",
  },
  "& .MuiAutocomplete-tag": {
    maxWidth: "100%",
    whiteSpace: "normal",
  },
  "& .MuiInputBase-input": {
    padding: "0px !important",
    minHeight: "0.6rem",
  },
};

//___________________________________________________________
export default function FilterOpponentAccordion({ personalMode }) {
  const classes = useStyles();

  const { isLoadingEmployees, empNext, empLastPage, employees } = useSelector(
    (state) => state.deptCollection
  );

  const { opponentId } = useSelector((state) => state.deptCollection);

  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [employeesList, setEmployeesList] = useState(employees || []);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getEmployeeService({ page: 1 })).then((res) => {
      setEmployeesList((prev) => [...prev, ...res.payload.data.data]);
    });
  }, []);

  useEffect(() => {
    if (employeesList.length === 0 && employees?.length > 0) {
      setEmployeesList(employees);
    }
  }, [employees]);

  useEffect(() => {
    dispatch(getEmployeeService({ page: 1, search_value: searchValue })).then(
      (res) => {
        setEmployeesList(res.payload.data.data);
        setCurrentPage(1);
      }
    );
  }, [searchValue, dispatch]);

  const handleLoadMoreEmployees = (nextPage) => {
    dispatch(
      getEmployeeService({ page: nextPage, search_value: searchValue })
    ).then((res) => {
      setEmployeesList((prev) => [...prev, ...res.payload?.data?.data]);
      setCurrentPage(nextPage);
    });
  };

  const [selected, setSelected] = useState([]);

  const FilterSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(FilterSchema),
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data) => {
    dispatch(
      getProceduressDetails({
        id: opponentId,
        page: 1,
        personalMode: personalMode,
      })
    );
  };

  const handleRemove = (fileId) => {
    setSelected((prevItems) => prevItems.filter((item) => item !== fileId));
  };

  const handleChange = (event, value) => {
    const ids = value?.map((item) => item.id);
    setSelectedEmployees(value);
    setSearchValue("");
    setValue("assigned_employees", ids, { shouldValidate: true });
  };

  const searchHandlerEmployee = (e) => {
    const search_value = e.target.value;
    setSearchValue(search_value);
  };

  // ------------- JSX Code ----------
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ ml: "5px" }}>
          <CustomizedAccordion
            disableGutters
            elevation={0}
            expanded={expanded}
            sx={{
              position: "relative",
              mr: "10px",
              mb: { xs: "10px", sm: "0px", md: "0px" },
            }}
          >
            <AccordionSummary
              onClick={() => {
                setExpanded(!expanded);
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={boxAccordionTitleStyle}
            >
              الفلاتر{" "}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                ...bodyOfAccordionStyle,
                position: "absolute",
                top: 0,
                left: -130,
                mt: "48px",
                zIndex: 1000,
              }}
            >
              <Box sx={boxStyle}>
                <Typography sx={{ ...lableSx, mt: "10px" }}>
                  {" "}
                  الإسناد
                </Typography>

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    multiple
                    options={employeesList}
                    getOptionLabel={(option) =>
                      `${option?.first_name} ${option?.last_name}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={handleChange}
                    sx={autocompleteSx}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="ابحث عن موظف"
                        onChange={searchHandlerEmployee}
                        value={searchValue}
                        multiline
                        InputProps={{
                          ...params.InputProps,
                          style: { height: "auto" },
                        }}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem {...props} key={option?.id}>
                        <ListItemIcon>
                          <Checkbox checked={selected} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${option?.first_name} ${option?.last_name}`}
                        />
                      </MenuItem>
                    )}
                    ListboxComponent={(props) => (
                      <ul {...props}>
                        {props.children}
                        {empNext && (
                          <LoadMoreButton
                            lastPage={empLastPage}
                            onLoadMore={handleLoadMoreEmployees}
                            loading={isLoadingEmployees}
                            activePage={currentPage}
                          />
                        )}
                      </ul>
                    )}
                  />
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", maxWidth: "100%", flexWrap: "wrap" }}>
                {selected?.map((select, index) => (
                  <Box key={index} value={select} sx={{ ...fileBoxStyle }}>
                    <Typography sx={nameFileStyle}>{select}</Typography>
                    <img
                      src="/assets/icons/remove.svg"
                      alt=""
                      style={{ paddingRight: "5px" }}
                      onClick={() => handleRemove(select)}
                    />
                  </Box>
                ))}
              </Box>

              <Typography sx={{ ...lableSx, mt: "10px" }}>
                {" "}
                تاريخ الإجراء
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <YearCustomDatePicker
                  name="firstDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
                <Box sx={{ ml: "20px", display: "flex" }}></Box>
                <YearCustomDatePicker
                  name="secondDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
              </Box>

              <Typography sx={{ ...lableSx, mt: "10px" }}>
                {" "}
                تاريخ المراجعة
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <YearCustomDatePicker
                  name="closeDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
                <Box sx={{ ml: "20px", display: "flex" }}></Box>
                <YearCustomDatePicker
                  name="secondCloseDate"
                  sx={txtFieldBorderStyle}
                  setValue={setValue}
                />
              </Box>

              <Button
                fullWidth
                size="large"
                type="submit"
                variant="Contained"
                sx={defaultValuesButtonStyle}
              >
                إزالة الفلاتر
              </Button>

              <Box sx={flexBoxStyle}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="Contained"
                  sx={{ ...topSaveButtonStyle, mr: "40px" }}
                >
                  تطبيق
                </Button>

                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="Contained"
                  sx={topCancleButtonStyle}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  الغاء
                </Button>
              </Box>
            </AccordionDetails>{" "}
          </CustomizedAccordion>
        </Box>
      </FormProvider>
    </>
  );
}
